import {React, useEffect, useRef, useState,useMemo,useCallback } from 'react'
import ReactQuill, { Quill } from 'react-quill'
import { Progress,Tabs, TabList, TabPanels, Tab, TabPanel,Box, Button, useColorMode,Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton,useToast,Spinner, Flex } from '@chakra-ui/react'
import 'react-quill/dist/quill.snow.css'
import './quill-styles.css'
import './custom-styles.css'
import _, { isEmpty } from "lodash"
import { theme, config} from './config.jsx'
import { VideoBlot } from './VideoBlot.jsx'
import {FileAttachmentBlot } from './AttachmentBlot'
import { isValidImageUrl,isValidVideoUrl} from './helper.jsx'
import "quill-mention"
import {default as cdnUrl} from '../../../../src/config/index'
import { FaMagic } from 'react-icons/fa'
import { HiOutlineExclamationCircle } from 'react-icons/hi'

const Clipboard = Quill.import('modules/clipboard')
const icons = Quill.import('ui/icons')
const codeBlock = 'code-block'
icons["undo"] = `<svg viewbox="0 0 18 18">
<polygon class="ql-fill ql-stroke" points="6 10 4 12 2 10 6 10"></polygon>
<path class="ql-stroke" d="M8.09,13.91A4.6,4.6,0,0,0,9,14,5,5,0,1,0,4,9"></path>
<title>Undo</title>
</svg>`
icons["redo"] = `<svg viewbox="0 0 18 18">
<polygon class="ql-fill ql-stroke" points="12 10 14 12 16 10 12 10"></polygon>
<path class="ql-stroke" d="M9.91,13.91A4.6,4.6,0,0,1,9,14a5,5,0,1,1,5-5"></path>
<title>Redo</title>
</svg>`
//icons.undo = config.icons.undo
//icons.redo = config.icons.redo
icons.bold = config.icons.bold
icons.italic = config.icons.italic
icons.underline = config.icons.underline
icons.strike = config.icons.strike
icons.blockquote = config.icons.blockquote
icons.list.ordered = config.icons.ordered
icons.list.bullet = config.icons.bullet
icons.script.sub = config.icons.sub
icons.script.super = config.icons.super
icons.color = config.icons.color
icons.background = config.icons.background
icons.image = config.icons.image
icons.video = config.icons.video
icons.link = config.icons.link
icons.attachment = config.icons.attachment
icons['code-block'] = config.icons.codeBlock
icons['indent']['-1'] = config.icons.indentBackward
icons['indent']['+1'] = config.icons.indentForward

const Delta = Quill.import('delta')
// Register Video Blot To Insert Video Tag For Video File //
VideoBlot.blotName = 'insertVideo'
VideoBlot.tagName = 'video'
Quill.register(VideoBlot)

FileAttachmentBlot.blotName = 'fileAttachment'
FileAttachmentBlot.tagName = 'a'
FileAttachmentBlot.className = 'file-attachment'

Quill.register(FileAttachmentBlot)
class CustomImageBlot extends ReactQuill.Quill.import('formats/image') {
    static create(value) {
        const node = super.create(value)
        node.setAttribute('src', this.sanitize(value.src))
        //  custom attribute
        node.setAttribute('uniqueAttr', value.myCustomAttribute)
        if(value.height!== undefined)
            node.setAttribute('height', value.height)
        if(value.width!== undefined)
            node.setAttribute('width', value.width)
        // node.setAttribute('style', `height: ${value.height}px; width: ${value.width}px;`)
        if(value.alt!== undefined)
            node.setAttribute('alt', value.alt)    
    
      return node
    }
  
    static value(node) {
      return {
        src: node.getAttribute('src'),
        myCustomAttribute: node.getAttribute('uniqueAttr'),
        height: node.getAttribute('height'),
        width: node.getAttribute('width'),
        alt:node.getAttribute('alt')
      }
    }
  }
ReactQuill.Quill.register('formats/image', CustomImageBlot)

/** Custom Video component to add video from url */
class CustomVideo extends Quill.import('formats/video') {
    static create(value) {
        const node = super.create(value)
        node.setAttribute('src', this.sanitize(value.src))
        node.setAttribute('style', `width:${value.width||'fit-content'};height: ${value.height||'fit-content'} !important;`)
        return node
    }

    static value(node) {
        return {
            src: node.getAttribute('src'),
            width: node.getAttribute('width'),
            height: node.getAttribute('height') }
        }
}

Quill.register('formats/video', CustomVideo)

// Main component function.

const  RichTextEditor = (props) => {
    const { editorHeight } = props
    const editorvalue = props.editorvalue || ''
    const mentionObj = props.mentionObj || ''
    const toast = useToast()
    const quillRef = useRef(null)
    const [value,setValue] = useState(editorvalue)
    const [isLoadingEditor,setIsLoadingEditor] = useState(false)
    const [videoModel,setVideoModel] = useState(false)
    const [imageModel,setImageModel] = useState(false)
    const [attachmentModel,setAttachmentModel] = useState(false)
    const [showVideoUpload,setShowVideoUpload] = useState(true)
    const [showVideoEmbaded,setShowVideoEmbaded] = useState(false)
    const [showImageUpload,setShowImageUpload] = useState(true)
    const [showImageEmbaded,setShowImageEmbaded] = useState(false)
    const [videoFile, setVideoFile] = useState(null)
    const [imageFile, setImageFile] = useState(null)
    const [myVideodata,setMyVideodata] = useState("")
    const [videoUrlText,setVideoUrlText] = useState("")
    const [imageUrlText,setImageUrlText] = useState("")
    const [currentUploadType,setCurrentUploadType] = useState("")
    const [isValidFormatVideo, setIsValidFormatVideo] = useState(true)
    const [imageUrlErr,setImageUrlErr] = useState(false)
    const [imageUploadErr,setImageUploadErr] = useState(false)
    
    const [videoUrlErr,setVideoUrlErr] = useState(false)
    const [valueEditor,setValueEditor] = useState("")
    const [isAttachment,setIsAttachment] = useState(false)
    const [imageHeight,setImageHeight] = useState("")
    const [imageWidth,setImageWidth] = useState("")
    const [attachmentFileErr,setAttachmentFileErr] = useState("")
    
    const [imageAlt,setImageAlt] = useState("")
    const [imagebase64Data,setImagebase64Data] = useState("")
    const [attachmentFile,setAttachmentFile] = useState("")
    const [isEditorFocused, setIsEditorFocused] = useState(false)
    
    // set default config
    let imageTypeConfigDefault = config.imageConfig.imageType
    let videoTypeConfigdefault = config.videoConfig.videoType
    let defaultModules = config.defaultToolbar
    const MAX_IMAGE_FILE_SIZE = config.imageConfig.imageSize
    const MAX_VIDEO_FILE_SIZE = config.videoConfig.videoSize
    const MAX_ATTACHMENT_FILE_SIZE = config.imageConfig.imageSize
    videoTypeConfigdefault = !isEmpty(props.videoTypeConfig) ? props.videoTypeConfig : videoTypeConfigdefault
    defaultModules = !isEmpty(props.toolBarModules) ? props.toolBarModules : defaultModules
    imageTypeConfigDefault = !isEmpty(props.imageTypeConfig) ? props.imageTypeConfig : imageTypeConfigDefault
    const configFormate = {
        validVideoFormats: videoTypeConfigdefault,
        supportedImageFormats: imageTypeConfigDefault
    }
    var IMAGE_MIME_REGEX = /^image\/(p?jpeg|gif|png)$/i
    useEffect(() => {
        let str = props?.editorvalue?.replace(/(\r\n|\r|\n)/g, '<div>')
        setValue(str)
    } , [props?.editorvalue] )

    // function getParentClassList(event) {
    //     const parentElement = event.target.parentNode
        
    //     if (parentElement) {
    //       const parentClassList = parentElement.classList
    //       console.log(parentClassList, 'parentClassListparentClassList')
    //       if(parentClassList && parentClassList.length > 0  && parentClassList?.value == 'ql-editor'){
    //             return true
    //       }
    //       else {
    //         const secondParent = parentElement.parentNode
    //         const upperParentClassList = secondParent.classList
    //         if(upperParentClassList && upperParentClassList?.length > 0 && upperParentClassList?.value == "ql-editor"){
    //             return true
    //         }
    //         else {
    //             return false
    //         }
    //       }
    //     }
    //   }
    
    // function getParentClassList(event) {
    //     const parentElement = event.target.parentNode
        
    //     if (parentElement) {
    //       const parentClassList = parentElement.classList
    //       if(parentClassList && parentClassList.length > 0  && parentClassList?.value == 'ql-editor'){
    //             return true
    //       }
    //       else {
    //         const secondParent = parentElement.parentNode
    //         const upperParentClassList = secondParent.classList
    //         if(upperParentClassList && upperParentClassList?.length > 0 && upperParentClassList?.value == "ql-editor"){
    //             return true
    //         }
    //         else {
    //             return false
    //         }
    //       }
    //     }
    //   }

    const iconList = {
        txt: `${cdnUrl?.cdnUri}/cdn/txt.png`,
        pdf: `${cdnUrl?.cdnUri}/cdn/pdf.png`,
        xls: `${cdnUrl?.cdnUri}/cdn/xlsx.png`,
        xlsx: `${cdnUrl?.cdnUri}/cdn/xlsx.png`,
        doc: `${cdnUrl?.cdnUri}/cdn/word.png`,
        docx: `${cdnUrl?.cdnUri}/cdn/word.png`,
        ppt: `${cdnUrl?.cdnUri}/cdn/ppt.png`,
        csv: `${cdnUrl?.cdnUri}/cdn/cvs.png`,
        tsv: `${cdnUrl?.cdnUri}/cdn/txt.png`,
        // defaultVideo:`${cdnUri}/cdn/thumbnail.png`,
        // defaultAttach: `${cdnUri}/cdn/txt.png`
        }
        
    /** Convert youtube and vimeo video urls to embedded format */
    function convertToEmbeddedUrl(url) {
        const videoSetting = props.videoSettings.find(setting=>new RegExp(setting.urlRegex,'i').test(url))
        const videoId = url.match(videoSetting?.urlRegex)[1]
        return videoSetting?.embeddedLink.replace(':videoId',videoId)

    }

    const handleFocus = () => {
        setIsEditorFocused(true)
      }
    
      const handleBlur = () => {
        setIsEditorFocused(false)
      }
    function hasNestedMedia(htmlString) {
        const tempDiv = document.createElement('div')
        tempDiv.innerHTML = htmlString

        function traverse(node) {
            if (node.tagName === 'IMG' || node.tagName === 'VIDEO' || node.tagName === 'IFRAME') 
                return true
            
            for (let childNode of node.childNodes) {
            if (traverse(childNode)) 
                return true  
            }
            return false
        }
        return traverse(tempDiv)
    }
        
    document.onpaste = function(e){
        // if(getParentClassList(e)){
        if(props?.usedOn=='cannedRes') { 
            let items = e.clipboardData.items
            if(items[0].kind=='file') {
                showToast({
                    title: 'Please Upload the image once the ticket is created',
                    status:'error',
                    description: "Pasting images is only supported in existing ticket / Pasting any Document is not allowed here"
                })
                return false
            }
        }else{
            // if(props?.caseId) {
            let maxSizeBytes = 1048576 // 1mb
            let targetElement = e.target
            let className = targetElement.className
        
            if(targetElement.className=='') {
                let items = e.clipboardData.items
                for (let i = 0; i < items.length; i++) {
                    if (IMAGE_MIME_REGEX.test(items[i].type)) {
                        setCurrentUploadType('image')
                        setIsLoadingEditor(true)
                        if (isIconDisabled('image') && props?.usedOn == 'articles') { 
                            setIsLoadingEditor(false)
                            props.showError()
                            return false
                        }
                        if (parseFloat(items[i].getAsFile().size/(1024*1024)) > MAX_ATTACHMENT_FILE_SIZE) { 
                            toast({
                                title: 'File size exceeds the maximum allowed size.',
                                description: `Only ${MAX_ATTACHMENT_FILE_SIZE}MB file size allowed, please try again`,
                                status: 'error',
                                duration: 3000,
                                position: "top-right",
                                isClosable: true,
                              })
                              setIsLoadingEditor(false)
                              return false

                        }

                        props.myUpload(items[i].getAsFile(),'image')
                        return false
                    } else {
                        if (items[i].kind === 'file') {
                            if(!props?.usedOn == 'articles') {
                                toast({
                                    title: 'File Not Supported',
                                    description: 'Only png, jpeg and jpg format are supported, please try again',
                                    status: 'error',
                                    duration: 3000,
                                    position: "top-right",
                                    isClosable: true,
                                })
                            }
                            return false
                        }
                        //Check if clipboard item has html content and html contains nested media
                        if (e.clipboardData.types.includes('text/html') && hasNestedMedia(e.clipboardData.getData('text/html') )) {
                            const htmlContent = e.clipboardData.getData('text/html')
                            //if media is not enabled for the editor then throw error 
                            if (props?.usedOn == 'articles') {
                                if (isIconDisabled('image')) {
                                    props.showError()
                                    return false
                                }  
                                //Remove iframes or videos if media is enabled
                                const tempDiv = document.createElement('div')
                                tempDiv.innerHTML = htmlContent

                                if (tempDiv.querySelector('iframe') || tempDiv.querySelector('video')) {    
                                    tempDiv.querySelectorAll('iframe, video').forEach(element => element.remove())
                                    const cleanHTML = tempDiv.innerHTML
                                    const quill = quillRef.current.getEditor()
                                    
                                    const range = quill.getSelection(true)
                                    quill.clipboard.dangerouslyPasteHTML(range.index, cleanHTML)
                                    quill.setSelection(range.index + cleanHTML.length, Quill.sources.SILENT)
                                    
                                    return false
                                }
                            }    
                        } 
                    }
                }
            }
        // } else {
        //     let targetElement = e.target
        //     let items = e.clipboardData.items
        //     if(items[0].kind === 'file') {
        //         toast({
        //             title: 'Please Upload the image once the ticket is created',
        //             description: 'Pasting images is only supported in existing ticket',
        //             status: 'error',
        //             duration: 3000,
        //             position: "top-right",
        //             isClosable: true,
        //           })
        //         return false
        //     }
        // }
    }
// }
    }
   
    


    const showToast=useCallback(({title,description,status}) => {
        toast({
            title: title,
            description: description,
            status: status || 'success',
            duration: 3000,
            position: "top-right",
            isClosable: true,
          })
    },[])
    useEffect(() => {
            if(props.linkOfUploadFile!='' && typeof props.linkOfUploadFile!='undefined' ) {
            const quill = quillRef.current.getEditor()
            const range = quill.getSelection(true)
            let HtmlVal = ''
            const imageUrl = props?.linkOfUploadFile?.doc
            const docName =  props?.linkOfUploadFile?.docName
            if(isAttachment===false) { 
                if(currentUploadType=='image') { 
                    // HtmlVal = `<div>&nbsp;<a href="${imageUrl}"><img src="${imageUrl}" uniqueAttr="${docName}" width="${imageWidth}px" height="${imageHeight}px"/><a/> &nbsp;</div>`
                    // quill.clipboard.dangerouslyPasteHTML(range.index, HtmlVal,'user')
                    const blot_params = {
                        src: imageUrl,
                        myCustomAttribute: docName,
                        alt:imageAlt?imageAlt:'Uploaded Image'
                    }
                    if (imageHeight)
                        blot_params.height = imageHeight
                    if (imageWidth)
                        blot_params.width = imageWidth
                    quill.insertText(range.index, '\u00A0', 'user')
                    quill.insertEmbed(range.index+1, 'image', blot_params)
                    quill.setSelection(range.index + 2, Quill.sources.SILENT) 

                } else {
                    HtmlVal = `<video src="${imageUrl}"></video>`
                    quill.insertEmbed(range.index, 'insertVideo', {
                    url:imageUrl,
                    controls: 'controls',
                    width: '50%',
                    height: '100%'
                    }, 'user')
                    quill.setSelection(range.index + 1, Quill.sources.SILENT) 
                }
                } else {
                    if(isAttachment===true) {
                    setAttachmentModel(false)
                    if (Object.keys(iconList).includes(currentUploadType)) {
                          // HtmlVal = `<a href="${imageUrl}"><img src="${iconList[currentUploadType]}"width="100px" height="100px" uniqueAttr="${docName}"/><a/>`
                        // quill.clipboard.dangerouslyPasteHTML(range.index, HtmlVal,'user')
                        // quill.insertText(range.index, '\u00A0', 'user')
                        // quill.insertEmbed(range.index, 'image', {
                        //     src: iconList[currentUploadType],
                        //     myCustomAttribute: docName,
                        //     height:100,
                        //     width:100
                        // })
                        quill.insertEmbed(range.index, 'fileAttachment', {
                            linkUrl:imageUrl,
                            imageUrl: iconList[currentUploadType],
                            myCustomAttribute: docName,
                        })
                        quill.setSelection(range.index + 1, Quill.sources.SILENT)
                    }else{
                        // HtmlVal = `&nbsp;<div><a href="${imageUrl}"><img width="100px" height="100px" src="${imageUrl}" data-my-unique-attr="${docName}"/><a/></div>&nbsp;`
                        // quill.clipboard.dangerouslyPasteHTML(range.index, HtmlVal,'user')
                        quill.insertEmbed(range.index, 'fileAttachment', {
                        linkUrl:imageUrl,
                        imageUrl: iconList[currentUploadType],
                        myCustomAttribute: docName,
                        })
                        quill.setSelection(range.index + 1, Quill.sources.SILENT)
                    }
                }
            }
            setImageModel(false)
            setImageModel(false)
            setIsLoadingEditor(false)
            setIsAttachment(false)
        } 
        if(props.errOnUpload) {
            showToast({
                    title: 'error',
                    status:'error',
                    description: props.errOnUpload
                })
        }
        setIsLoadingEditor(false)
    }, [props.linkOfUploadFile,props.errOnUpload,])
    

    function handleChangeEditor(newValue, delta, source, editor) {
        let str = newValue?.replace(/(\r\n|\r|\n)/g, '<br>')
        setValue(str)
        if(props.onChange){
            props.onChange(newValue)
        }
    }
      
    function  handleVideoUrlChange(e)  {
        setVideoUrlText(e.target.value)
        const isValid = isValidVideoUrl(e.target.value,props.videoSettings) // true or false
        return !isValid ? (setVideoUrlErr(true), false) : (setVideoUrlErr(false), true)
    }

    
    function handleImageUrlChange(e)  {
        setImageUrlText(e.target.value)
        const isValid = isValidImageUrl(e.target.value) // true or false
        return !isValid ? (setImageUrlErr(true), false) : (setImageUrlErr(false), true)
    }
    const addVideoFrmUrlCancel = () => {
        setVideoModel(false)
        setVideoUrlText("")
        setVideoUrlErr(false)
    }
    function addVideoFrmUrl() {
        setIsLoadingEditor(true)
        const isValid = isValidVideoUrl(videoUrlText,props.videoSettings) // true or false
        if(!isValid) {
            setVideoUrlErr(true)
            setIsLoadingEditor(false)
            return false
        } 
        setVideoUrlErr(false)
        const embeddedUrl = convertToEmbeddedUrl(videoUrlText)
        const quill = quillRef.current.getEditor()
        const range = quill.getSelection(true)
        // quill.insertEmbed(range.index, 'video', embeddedUrl, 'user')
         quill.insertEmbed(range.index, 'video', {
            src: embeddedUrl,
            controls: 'controls',
            width: 'fit-content',
            height: 'fit-content'
         }, 'user')
        quill.setSelection(range.index + 1, Quill.sources.SILENT)
        props.mapEmbeddedVideo(embeddedUrl,videoUrlText)
        setVideoModel(false)
        setVideoUrlText("")
        setIsLoadingEditor(false)
    }

    
      useEffect(() => {
        const handleClickColor = (e) => {
        
            let qlbackgroundEle = document.querySelector('.ql-background')
            if(qlbackgroundEle) qlbackgroundEle.classList.remove("ql-expanded")     
           
            let qlalignEle = document.querySelector('.ql-align')
            if(qlalignEle) qlalignEle.classList.remove("ql-expanded")
           
            let qlsizeEle = document.querySelector('.ql-size')
            if(qlsizeEle) qlsizeEle.classList.remove("ql-expanded")
           
            let qlfontEle = document.querySelector('.ql-font')
            if(qlfontEle) qlfontEle.classList.remove("ql-expanded")
        }

        const handleClickBG = (e) => {
            let qlcolorEle = document.querySelector('.ql-color')
            if(qlcolorEle) qlcolorEle.classList.remove("ql-expanded")

            
            let qlalignEle = document.querySelector('.ql-align')
            if(qlalignEle) qlalignEle.classList.remove("ql-expanded")
            
            let qlsizeEle = document.querySelector('.ql-size')
            if(qlsizeEle) qlsizeEle.classList.remove("ql-expanded")
            
            let qlfontEle = document.querySelector('.ql-font')
            if(qlfontEle) qlfontEle.classList.remove("ql-expanded")
            
        }
        const handleClickAlign = (e) => {
            let qlcolorEle = document.querySelector('.ql-color')
            if(qlcolorEle) qlcolorEle.classList.remove("ql-expanded")
            
            let qlalignEle = document.querySelector('.ql-background')
            if(qlalignEle) qlalignEle.classList.remove("ql-expanded")

            let qlsizeEle = document.querySelector('.ql-size')
            if(qlsizeEle) qlsizeEle.classList.remove("ql-expanded")
            
            let qlfontEle = document.querySelector('.ql-font')
            if(qlfontEle) qlfontEle.classList.remove("ql-expanded")
            
        }
        const handleClickSize = (e) => {
            let qlcolorEle = document.querySelector('.ql-color')
            if(qlcolorEle) qlcolorEle.classList.remove("ql-expanded")
            
            let qlalignEle = document.querySelector('.ql-background')
            if(qlalignEle) qlalignEle.classList.remove("ql-expanded")
            
            let qlsizeEle = document.querySelector('.ql-align')
            if(qlsizeEle) qlsizeEle.classList.remove("ql-expanded")

            let qlfontEle = document.querySelector('.ql-font')
            if(qlfontEle) qlfontEle.classList.remove("ql-expanded")
        }
        const handleClickFont = (e) => {
            let qlcolorEle = document.querySelector('.ql-color')
            if(qlcolorEle) qlcolorEle.classList.remove("ql-expanded")

            let qlalignEle = document.querySelector('.ql-background')
            if(qlalignEle) qlalignEle.classList.remove("ql-expanded")

            let qlsizeEle = document.querySelector('.ql-align')
            if(qlsizeEle) qlsizeEle.classList.remove("ql-expanded")
            
            let qlfontEle = document.querySelector('.ql-size')
            if(qlfontEle) qlfontEle.classList.remove("ql-expanded")
        }
        
        

        
    
        const timer = setTimeout(() => {
          const element = document.querySelector('.ql-color')
          if (element) {
            element.addEventListener('click', handleClickColor)
            
          }

          const elementBG = document.querySelector('.ql-background')
          if (elementBG) {
            elementBG.addEventListener('click', handleClickBG)
          }

          const elementAlign = document.querySelector('.ql-align')
          if (elementAlign) {
            elementAlign.addEventListener('click', handleClickAlign)
          }
          const elementSize = document.querySelector('.ql-size')
          if (elementSize) {
            elementSize.addEventListener('click', handleClickSize)
          }

          const elementFont = document.querySelector('.ql-font')
          if (elementFont) {
            elementFont.addEventListener('click', handleClickFont)
          }

          
        }, 2000)
    
        return () => {
          clearTimeout(timer)
          const element = document.querySelector('.ql-color')
          if (element) {
            element.removeEventListener('click', handleClickColor())
          }

          const elementBG = document.querySelector('.ql-background')
          if (elementBG) {
            elementBG.removeEventListener('click', handleClickBG)
          }

          const elementAlign = document.querySelector('.ql-align')
          if (elementAlign) {
            elementAlign.removeEventListener('click', handleClickAlign)
          }

          const elementSize = document.querySelector('.ql-size')
          if (elementSize) {
            elementSize.removeEventListener('click', handleClickSize)
          }

          const elementFont = document.querySelector('.ql-font')
          if (elementFont) {
            elementFont.removeEventListener('click', handleClickFont)
          }
        }
      }, [])
        
    const addImageFrmUrlCancel = () => {
        setImageUrlErr(false)
        setImageUrlText("")
        setImageModel(false)
    }
    function addImageFrmUrl() {
        setIsLoadingEditor(true)
        const isValid = isValidImageUrl(imageUrlText) // true or false
        if(!isValid) {
            setImageUrlErr(true)
            setIsLoadingEditor(false)
            return false
        } else {
            setImageUrlErr(false)
        }
        const quill = quillRef.current.getEditor()
        const range = quill.getSelection(true)
        // quill.insertEmbed(range.index, 'image', imageUrlText, 'user')
        const blot_params = {
                src: imageUrlText,
                alt:imageAlt?imageAlt:'Uploaded Image'
            }
            if (imageHeight)
                blot_params.height = imageHeight
            if (imageWidth)
            blot_params.width = imageWidth
        quill.insertText(range.index, '\u00A0', 'user')
        quill.insertEmbed(range.index+1, 'image', blot_params)
        quill.setSelection(range.index + 2, Quill.sources.SILENT) 
        setImageModel(false)
        setImageUrlText("")
        setIsLoadingEditor(false)
    }
    
    const handleFileChangeAttachment = (event) => {
        setIsAttachment(true)
        //setIsLoadingEditor(true)
        let fileType = event.target.files[0].name.split('.').pop().toLowerCase()
        setCurrentUploadType(fileType)
        const uploadSize = event.target.files[0].size
        const fileExtension = event.target.files[0].name.split('.').pop().toLowerCase()
        const otherFileExtensions = ["png", "jpg", "jpeg", "gif", "bmp", "txt", "pdf", "xls", "xlsx", "doc", "docx", "ppt", "csv", "tsv"]
        if (parseFloat(uploadSize/(1024*1024)) > MAX_ATTACHMENT_FILE_SIZE)
            setAttachmentFileErr(`Please select file below ${MAX_ATTACHMENT_FILE_SIZE}MB to upload`)
        else if (!otherFileExtensions.includes(fileExtension))
            setAttachmentFileErr("Please select a valid file!")
        else {
            setAttachmentFile(event.target.files[0])
            setAttachmentFileErr('')
        }
       // props.myUpload(event.target.files[0],fileType)
    }
    const handleAttachmentUploadCancel = () => {
        setAttachmentFileErr("")
        setAttachmentModel(false)
        setAttachmentFile("")
    }
    // const handleAttachmentUpload = () => {
    //     const uploadSize=attachmentFile?.size  ||  3145728  //DEFIINING UPLOAD SIZE OF THE IMAGE
    //     const fileExtension = attachmentFile.name.split('.').pop().toLowerCase()
    //     const otherFileExtensions = ["png", "jpg", "jpeg", "gif", "bmp", "txt", "pdf", "xls", "xlsx", "doc", "docx", "ppt", "csv", "tsv"]
    //     if(attachmentFile!='' && otherFileExtensions.includes(fileExtension) && uploadSize<=3145728) { //add this line
    //         setAttachmentModel(false)
    //         setIsLoadingEditor(true)
    //         let fileType = attachmentFile.name.split('.').pop().toLowerCase()
    //         props.myUpload(attachmentFile,fileType)
    //     } else {
    //         uploadSize>3145728? setAttachmentFileErr("Please select image below 3MB to upload"): setAttachmentFileErr("Please select a valid file!")
    //         setAttachmentFile('')
    //     }
    // }
    const handleAttachmentUpload = () => {
        // const uploadSize=attachmentFile?.size  ||  3145728  //DEFIINING UPLOAD SIZE OF THE IMAGE
        // const fileExtension = attachmentFile.name.split('.').pop().toLowerCase()
        // const otherFileExtensions = ["png", "jpg", "jpeg", "gif", "bmp", "txt", "pdf", "xls", "xlsx", "doc", "docx", "ppt", "csv", "tsv"]
        // if(attachmentFile!='' && otherFileExtensions.includes(fileExtension) && uploadSize<=3145728) { //add this line
            setAttachmentModel(false)
            setIsLoadingEditor(true)
            let fileType = attachmentFile.name.split('.').pop().toLowerCase()
            props.myUpload(attachmentFile,fileType)
        // } else {
        //     uploadSize>3145728? setAttachmentFileErr("Please select file below 3MB to upload"): setAttachmentFileErr("Please select a valid file!")
        //     setAttachmentFile('')
        // }
    }
    const handleFileChange = (event) => {
        setVideoFile(event.target.files[0])
        setCurrentUploadType("video")
    }
    const handleFileChangeImage = (event) => {
        setImageFile(event.target.files[0])
        setCurrentUploadType("image")
        const file = event.target.files[0]
        const reader = new FileReader()

        reader.onload = (e) => {
            setImagebase64Data(e.target.result)
            setImageUploadErr(false)
        }

        reader.readAsDataURL(file)
        
    }
    const showImageUploadModal = () => {
        setImageModel(true)
    } 
    const handleUploadCancel = () => {
        setVideoModel(false)
        setVideoFile('')
        setIsValidFormatVideo(true)
    }
    const handleUpload = () => {
        const selectedFile = videoFile
        if(selectedFile===null) {
            setIsValidFormatVideo(false)
            return false
        }
        if(selectedFile=='') {
            setIsValidFormatVideo(false)
            return false
        }
        const selectedFormat = selectedFile.name.split('.').pop()
        if (configFormate.validVideoFormats.includes(selectedFormat)) {
            setIsValidFormatVideo(true) 
        } else {
            setIsValidFormatVideo(false)
            return false
        }
        if(parseFloat(videoFile.size/(1024*1024)) > MAX_VIDEO_FILE_SIZE) return false
        setIsLoadingEditor(true)
        props.myUpload(videoFile,'video')
        setVideoModel(false)
        setVideoFile('')
        
    }
    const handleUploadImageCancel = () => {
        setImageUploadErr(false)
        setImageModel(false)
        setImageFile("")
        setImagebase64Data("")
    } 
    const handleUploadImage = () => {
        if(!imageFile) {  
            setImageUploadErr(true) 
            return false 
        }

        setIsLoadingEditor(true)
        props.myUpload(imageFile,'image')
        setImageModel(false)
        setImageFile("")
        
    }

    const openVideoFileUpload =  () =>{
        setShowVideoUpload(true)
        setShowVideoEmbaded(false)
    }
    const openVideoUploadText =  () =>{
        setShowVideoUpload(false)
        setShowVideoEmbaded(true)
    }
    const openImageFileUpload =  () =>{
        setShowImageUpload(true)
        setShowImageEmbaded(false)
    }
    const openImageUploadText =  () =>{
        setShowImageUpload(false)
        setShowImageEmbaded(true)
    }

    const isIconDisabled = icon => {
        const iconElement = document.querySelector(`.ql-toolbar .ql-${icon}`)
        return iconElement.classList.contains('ql-disabled')
    }
    const modules = useMemo(() => ({
        history: {
            delay: 2500,
            maxStack: 50,
            userOnly: false,
        },
        toolbar: {
            container: 
                defaultModules,
            handlers: {
                undo(this: any) {
                    this.quill?.history?.undo()
                },
                redo(this: any) {
                    this.quill?.history?.redo()
                },
                video: () => {
                    if (props?.usedOn == 'articles' && isIconDisabled('video'))
                        props?.showError()
                    else {
                        setVideoModel(true)
                        openVideoFileUpload()
                    }
                },
                image: () => {
                    if (props?.usedOn == 'articles' && isIconDisabled('image'))
                        props?.showError()
                    else {
                        setImageModel(true)
                        openImageFileUpload()
                    }
                },
                attachment: () => {
                    if (props?.usedOn == 'articles' && isIconDisabled('attachment'))
                        props?.showError()
                    else {
                        setAttachmentModel(true)
                        setAttachmentFile("")
                    }
                }, 
            },
            
        },
        mention: mentionObj,
  
    }), [defaultModules])
        
    const { colorMode } = useColorMode()
    const editorStyles = {
        height: editorHeight?.innerH || '301px'
    }
    
    useEffect(()=>{
        if(props?.readOnly && attachmentModel){
            setAttachmentModel(false)
        }
    }, [attachmentModel, props?.readOnly])
    return (
    <Box position={'relative'} height= {props?.customEditorStyles ? props?.customEditorStyles : (editorHeight?.outerH || props.customStyle?"242px":"375px") } className={colorMode === 'dark' ? 'dark-mode' : ''}>

        {isLoadingEditor &&
            <Progress size='xl' height={2} isIndeterminate />
        }
        {props?.genAiconfig ? <style>{`.ql-editor{height:89% !important}`}</style> : <style>{`.ql-editor{height: 100% !important}`}</style>}
        <ReactQuill
            value={value}
            key={props?.key}
            ref={quillRef}
            modules={modules}
            placeholder={props?.placeholder ??"Enter Description"}
            theme="snow"
            id="editor"
            onChange={handleChangeEditor}
            style={props?.customEditorStyles ?? editorStyles}
            readOnly={props?.readOnly || false}
            onFocus={handleFocus}
            onBlur={handleBlur}
            
        />
        {props?.genAiconfig && props?.autoComplete && <div className="editor-footer" style={{borderTop: '1px solid #E2E2E2',backgroundColor: '#F8F9FD',height:'44px',padding:'10px 19px',position: 'absolute',zIndex: 1,width: '100%',borderBottomLeftRadius: '10px',borderBottomRightRadius: '10px',border: '1px solid #E2E2E2'}}>
        <div style={{display:'flex',justifyContent:'flex-end',alignItems:'center'}}>
            <div style={{display:'flex',alignItems:'center',cursor:props?.disableAutocompleteText?'pointer':'not-allowed'}} {...(props?.disableAutocompleteText)?{onClick:()=>props.autoComplete.enableAutoComplete()}:{}}>
               <FaMagic size={'14.88px'} color={props?.disableAutocompleteText?'#E79B04':'#A0A4A8'}/>
               <p style={{fontFamily: 'Inter',fontSize: '14px',fontWeight: 500,lineHeight: "16.94px",color:props?.disableAutocompleteText?'#E79B04':'#A0A4A8',marginLeft:'10px'}}>
               Auto-complete
               </p>
            </div>
            <div className='autocomplete' style={{position:'relative'}}>
                <div className='parent'>
                    <div style={{marginLeft:'10px'}}><HiOutlineExclamationCircle style={{strokeWidth: 2}} size={'20px'} color='#94A3B8'/></div>
                    <div style={{width:'798px',height:'40px',borderRadius:'5px',backgroundColor:'#F0F6FF',   boxShadow: "0px 10px 15px -3px rgba(0, 0, 0, 0.10), 0px 4px 6px -2px rgba(0, 0, 0, 0.05), 0px 0px 0px 1px rgba(0, 0, 0, 0.05)",position:'absolute',marginTop:'-20px',marginLeft:'-790px'}} className='child'>
                        <div style={{padding:'10px',display:'flex'}}>
                            <div><FaMagic size={"20px"} color="#ffa500"/></div>
                            <p style={{fontSize:'14px',fontWeight:'400',lineHeight:'16.94px',color:'#6B7280',marginLeft:'10px'}}>
                                Create high-quality articles effortlessly - Use this feature to take AI's help in writing a well-crafted article.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </div>}
        {videoModel && 
            <div>
                <div id="myModal" className="modal">
                    <div className="modal-content mcp_p" >
                        <span className="close" onClick={() => { setVideoModel(false  ) }}>&times;</span>
                        <Tabs>
                            <TabList>
                                <Tab onClick={openVideoFileUpload}>File Upload</Tab>
                                <Tab onClick={openVideoUploadText}>URL</Tab>
                            </TabList>
                            <TabPanels>
                                <TabPanel>
                                {showVideoUpload &&
                                    <div>
                                        <div className='custom_upload'>
                                            <input className="videoFileInput" accept="video/*"  type="file" name="myFile" onChange={handleFileChange} />
                                        </div>
                                        {videoFile && <div style={{marginTop:'10px'}}><span> Selected File Name- {videoFile.name}  </span></div> }
                                        {videoFile && parseFloat(videoFile.size/(1024*1024)) > MAX_VIDEO_FILE_SIZE && (
                                            <div className="error">File size exceeds 10 MB.</div>
                                        )}
                                        {!isValidFormatVideo && <div className="error">Invalid video format. Please select a valid format: {configFormate.validVideoFormats.join(', ')}</div>}
                                        <div className="rdw-image-modal-btn-section btnupload">
                                            <Button className="rdw-image-modal-btn" onClick={handleUpload} isDisabled={!videoFile|| (videoFile && parseFloat(videoFile.size/(1024*1024)) > MAX_VIDEO_FILE_SIZE) || !isValidFormatVideo  } type="button">Upload</Button>
                                            <Button className="rdw-image-modal-btn" style={{marginLeft:"10px"}} onClick={handleUploadCancel}>Cancel</Button>
                                            </div>
                                        
                                    </div>
                                }
                                </TabPanel>
                                <TabPanel>
                                {showVideoEmbaded &&
                                    <div>
                                        <input value={videoUrlText} placeholder='Enter Video Url'  onChange={handleVideoUrlChange}   className="videoUrlTextBox"  type="text"
                                        name="myFileText" />
                                        <div></div>
                                        {videoUrlErr && <div className="error">Invalid video url format. Please enter a valid supported url.</div>}
                                    
                                        <Button className="videoUrlSubmit" isDisabled={!videoUrlText|| videoUrlErr} onClick={addVideoFrmUrl}>Submit</Button>
                                        <Button className="videoUrlSubmit" style={{marginLeft:"10px"}} onClick={addVideoFrmUrlCancel}>Cancel</Button>
                                    </div>
                                }
                                </TabPanel>
                            </TabPanels>
                        </Tabs>
                    </div>
                </div>

            </div>}
      
        {imageModel && 
        <div>
            <div  className="modal">
                <div className="modal-content mcp_p">
                    <span className="close" onClick={()=>{setImageModel(false)}}>&times;</span>
                      <Tabs>
                            <TabList>
                                <Tab onClick={openImageFileUpload}>File Upload</Tab>
                                <Tab onClick={openImageUploadText}>URL</Tab>
                            </TabList>
                        <TabPanels>
                            <TabPanel>
                            {showImageUpload &&
                        <div>
                            {!imageFile &&
                                <div className='custom_upload'>
                                    <input className="videoFileInput" accept="image/*"  type="file" name="myFile" onChange={handleFileChangeImage}/>
                                    
                                </div>
                            }
                            {imageFile && 
                                <div style={{marginTop:'10px'}}><span>  Selected File Name- {imageFile.name}</span>
                                    <div><img src={imagebase64Data} height="180px" width="150px" /></div>
                                </div>}
                               {imageFile && parseFloat(imageFile.size/(1024*1024)) > MAX_IMAGE_FILE_SIZE && (
                                    <div className="error">File size exceeds {MAX_IMAGE_FILE_SIZE} MB.</div>
                                )}  
                              {imageUploadErr && <div className="error">Please select valid image.</div>}
                            <div className='imageHWContainer'>
                            <input name="height" className="rdw-image-modal-size-input inputimghwupload"  placeholder="Height"  onChange={(e) => { setImageHeight(e.target.value) }}  />
                            <input name="width" className="rdw-image-modal-size-input inputimghwupload" placeholder="Width"  onChange={(e) => { setImageWidth(e.target.value) }} />
                            <input name="alt" className="rdw-image-modal-size-input inputimghwupload" placeholder="Alt"  onChange={(e) => { setImageAlt(e.target.value) }} />
                            </div>
                            <Button className="chakra-button chakra-button videoUrlSubmit css-taj3dd css-taj3dd" isDisabled={!imageFile||imageUploadErr}  onClick={handleUploadImage}>Upload </Button>
                            <Button  className="chakra-button videoUrlSubmit css-taj3dd" style={{marginLeft:'10px'}} onClick={handleUploadImageCancel}>Cancel</Button>
                        </div>}
                            </TabPanel>
                            <TabPanel>
                            {showImageEmbaded &&
                    <div>
                        <input value={imageUrlText}   onChange={handleImageUrlChange} placeholder='Enter Image Url'   className="videoUrlTextBox"  type="text" name="myFileText"/>
                        {imageUrlErr && <div className="error">Invalid image url. Please enter valid a secure url.</div>}
                            <div className='imageHWContainer'>
                            <input name="height" className="rdw-image-modal-size-input inputimghw" placeholder="Height"  onChange={(e) => { setImageHeight(e.target.value) }}  />
                            <input name="width" className="rdw-image-modal-size-input inputimghw" placeholder="Width"  onChange={(e) => { setImageWidth(e.target.value) }} />
                            <input name="alt" className="rdw-image-modal-size-input inputimghw" placeholder="Alt"  onChange={(e) => { setImageAlt(e.target.value) }} />
                            </div>
                            <Button className="chakra-button videoUrlSubmit css-taj3dd" isDisabled={!imageUrlText|| imageUrlErr } onClick={addImageFrmUrl}>Submit</Button>
                        <Button  className="chakra-button videoUrlSubmit css-taj3dd" style={{marginLeft:'10px'}} onClick={addImageFrmUrlCancel}>Cancel</Button>
                    </div>} 
                            </TabPanel>
                        </TabPanels>
                        </Tabs>
                </div>
            </div>

        </div>}

        <Modal isOpen={!props?.readOnly && attachmentModel && attachmentModel } onClose={() => setAttachmentModel(false)}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Select File to Attach</ModalHeader>
          <ModalCloseButton
          onClick={() => setAttachmentFileErr(" ")}
          />
          <ModalBody>
            {!attachmentFile && (
              <div>
                <div>
                  {/* <h1 className="modelHeading">Select File to Attachment</h1> */}
                  <div className="custom_upload">
                    <input
                      className="videoFileInput"
                      type="file"
                      name="myFile"
                      onChange={handleFileChangeAttachment}
                      accept='application/*,text/plain'
                    />
                  </div>
                </div>
                </div>
            )}
            {attachmentFile && (
              <div style={{ marginTop: '10px' }}>
                <span>Selected File Name - {attachmentFile.name}</span>
              </div>
            )}
            {attachmentFileErr !== '' && <div className="error">{attachmentFileErr}</div>}
          </ModalBody>
          <ModalFooter>
            <Flex className="rdw-image-modal-btn-section btnupload">
              <Button
                className="rdw-image-modal-btn"
                onClick={handleAttachmentUpload}
                type="button"
                isDisabled={ attachmentFileErr !== '' || !attachmentFile}                
              >
                Submit
              </Button>
              <Button
                className="rdw-image-modal-btn"
                style={{ marginLeft: '10px' }}
                onClick={handleAttachmentUploadCancel}
              >
                Cancel
              </Button>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>
    
    </Box>
  )

}

export default RichTextEditor
