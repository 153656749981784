import React, { useEffect, useState } from "react"
import {
    DrawerBody,
    DrawerContent,
    DrawerHeader,
    DrawerOverlay,
    Drawer,
    DrawerFooter,
    Input,
    Textarea,
    Box,
    Flex,
    Text,
    Button,
    Switch,
    Image
} from "@chakra-ui/react"
import { FiArrowLeftCircle } from "react-icons/fi"
import { FaMagic } from "react-icons/fa"
import { HiOutlineExclamationCircle } from "react-icons/hi"
import { AiOutlinePlusCircle } from 'react-icons/ai'
import { DeleteIcon } from '@chakra-ui/icons'
import './articles.css'
import { BsExclamationTriangle } from "react-icons/bs"
import { useMutation,useQueryClient } from 'react-query'
import { useServicesContext } from "services/apiServices"
import marked from 'marked'
import RezoloLogoLoader from './../../../assets/RezolveLogoLoader.gif'
const AutoComplete = ({ selectedArticle,preserveAutoComplete,autoCompleteConfig }) => {
    
    const {apiCall:callBackend} = useServicesContext()
    const [autoCompleteFields, setAutoCompleteFields] = useState({
        title: autoCompleteConfig?.articleContent?.title ??'',
        intent: '',
        briefDescription: autoCompleteConfig?.articleContent?.description ?? '',
        strawman: '',
        restrictedWebsiteCheckbox: false,
        referenceWebsite: [''],
        citation:false
    })
    const [isTitlePresented ,setIsTitlePreseneted] = useState(autoCompleteConfig?.articleContent?.title ??'')
    const [onSubmitLoader,setOnSubmitLoader] = useState(false)
    const [errorMessage, setErrorMessage] = useState({ key: null, errorTemplate: null })
    
    useEffect(()=>{
        const checkAcData = preserveAutoComplete?.preserveAutoCompleteData.find((data)=>data.id === (selectedArticle?.id ?? "create"))
        if(selectedArticle?.id && checkAcData?.id !== 'create' && checkAcData){
            setAutoCompleteFields(checkAcData.autocompleteDetails)
            setIsTitlePreseneted(checkAcData.autocompleteDetails.title)
            resetError()
        }
        if((autoCompleteFields?.title === checkAcData?.autocompleteDetails?.title && checkAcData?.id === "create" )){
            setAutoCompleteFields(checkAcData.autocompleteDetails)
            setIsTitlePreseneted(checkAcData.autocompleteDetails.title)
            resetError()
        }       
    },[])

    const requiredFields = ['title', 'briefDescription']
    const resetError =()=>{
        setErrorMessage({ key: null, errorTemplate: null })
    }
    const resetAutoCompleteFields = ()=>{
        setAutoCompleteFields({title: '',intent: '', briefDescription: '', strawman: '', restrictedWebsiteCheckbox: false, referenceWebsite: [''],citation:false })
        setIsTitlePreseneted('')
    }
    const onChangeTextFields = (event) => {
        resetError()
        if(event.target.value.length > 255){
            setAutoCompleteFields({ ...autoCompleteFields, [event.target.name]: event.target.value.substring(0, 255)})
            setErrorMessage({ key: event.target.name, errorTemplate: `Only 255 characters are permitted.` })
            return
        }
        setAutoCompleteFields({ ...autoCompleteFields, [event.target.name]: event.target.value })
    }
    const onChangeReferenceWebsite = (index, value) => {
        resetError()
        if(value >= 255){
            setErrorMessage({ key: 'referenceWebsite', errorTemplate: `Only 255 characters are permitted.` })
            return
        }
        const newRefernceWebsite = autoCompleteFields.referenceWebsite
        newRefernceWebsite[index] = value
        setAutoCompleteFields({ ...autoCompleteFields, referenceWebsite: newRefernceWebsite })
    }
    const addReferenceWebsite = () => {
        resetError()
        if (!autoCompleteFields?.referenceWebsite[autoCompleteFields.referenceWebsite.length - 1]?.trim()) {
            setErrorMessage({ key: 'referenceWebsite', errorTemplate: `The current field cannot be left empty.` })
            return
        }
        setAutoCompleteFields({ ...autoCompleteFields, referenceWebsite: [...autoCompleteFields.referenceWebsite, ''] })
    }
    const removeReferenceWebsite = (removeIndex) => {
        resetError()
        const newRefernceWebsite = autoCompleteFields.referenceWebsite.filter((data, index) => index !== removeIndex)
        setAutoCompleteFields({ ...autoCompleteFields, referenceWebsite: newRefernceWebsite })
    }
    const errorTemplate = (errorText) => {
        return <Flex alignItems={"center"} gap={2} mt={'10px'}>
            <BsExclamationTriangle
                size={'20px'}
                color="#CF3626"
            />
            <div
                style={{
                    fontSize: "14px",
                    fontWeight: 400,
                    lineHeight: "20px",
                    color: "#6B7280",
                }}
            >
                {errorText}
            </div>
        </Flex>
    }
    const validateFields = (fields) => {
        resetError()
        for (let field of Object.keys(fields)) {
            if (requiredFields.includes(field) && !fields[field]?.trim()) {
                let fieldName = field.replace(/([A-Z])/g, ' $1').replace(/^./, (match) => match.toUpperCase())
                setErrorMessage({ key: field, errorTemplate: `${fieldName} cannot be left empty` })
                setOnSubmitLoader(false)
                return false
            }
        }
        for(let website of autoCompleteFields?.referenceWebsite?.filter((data)=>data?.trim() !== '')){
            if(!isValidURL(website)){
                setErrorMessage({ key: 'referenceWebsite', errorTemplate: `Provide a valid URL` })
                setOnSubmitLoader(false)
                return false
            } 
        }
        if(autoCompleteFields?.referenceWebsite?.length >= 2){
            autoCompleteFields?.referenceWebsite.forEach((website,index)=>{
                    if(website?.trim() === ''){
                        setErrorMessage({ key: 'referenceWebsite', errorTemplate: `Reference websites in position ${index+1} cannot be left empty.` })
                        setOnSubmitLoader(false)
                        return false
                    }
            })
        }
        if(autoCompleteFields?.restrictedWebsiteCheckbox && autoCompleteFields?.referenceWebsite?.filter((data)=>data?.trim() !== '').length === 0){
            setErrorMessage({ key: 'referenceWebsite', errorTemplate: `Provide at least one URL, as the 'Restrict to Reference Websites' toggle is enabled` })
            setOnSubmitLoader(false)
            return false
        }
        return true 
    }
    const convertMarkdown = (text) => {
        return marked(text)
    }
    const markdownToText = (markdown) => { 
        let html = marked(markdown)
        let text = html.replace(/<\/?[^>]+(>|$)/g, "")
        text = text.replace(/\n/g, " ").replace(/\s+/g, " ").trim()
        return text
      }
      
    const autoCompleteDescriptions = async (urlBody,headers={}) => {  
        const data = JSON.parse(JSON.stringify(urlBody?.data ?? {}))
        data.title = data?.title?.substring(0, 255) ?? data?.title ?? ''
        data.intent = data?.intent?.substring(0, 255) ?? data?.intent ?? ''
        data.strawman = data?.strawman?.substring(0, 255) ??  data?.strawman ?? ''
        data.briefDescription = markdownToText(data?.briefDescription?.substring(0, 255)) ?? data?.briefDescription ?? ''
        data.referenceWebsite = data?.referenceWebsite.filter((val)=> val?.trim() !== '').map((value)=> value?.substring(0, 255) ?? value ?? '')
        mutation.mutate({
            options: {
            url: `InputFiles/autoCompleteDescriptions`,
            method: "POST",
            headers
            },
            payload: {data},
        })
    }
    const mutation = useMutation(
            callBackend,
            {
                onSuccess:(data,headers)=>{
                    setOnSubmitLoader(false)
                    autoCompleteConfig.onSubmitAutoComplete(convertMarkdown(data?.article))
                    if(preserveAutoComplete?.preserveAutoCompleteData.find((data)=>data.id === (selectedArticle?.id ?? "create"))){
                        preserveAutoComplete?.FindAndReplaceAutocompleteData(selectedArticle?.id ?? "create",autoCompleteFields ?? {})
                    }else{
                        preserveAutoComplete?.addAutoCompleteData({id:selectedArticle?.id ?? "create",autocompleteDetails:autoCompleteFields})
                    }
                    resetAutoCompleteFields()
                    resetError()
                    setCurrentMessageIndex(0)
                    autoCompleteConfig.disableAutoComplete()
                },
                onError: (err,headers) => {
                    setOnSubmitLoader(false)
                    setCurrentMessageIndex(0)
                    if(preserveAutoComplete?.preserveAutoCompleteData.find((data)=>data.id === (selectedArticle?.id ?? "create"))){
                        preserveAutoComplete?.FindAndReplaceAutocompleteData(selectedArticle?.id ?? "create",autoCompleteFields ?? {})
                    }else{
                        preserveAutoComplete?.addAutoCompleteData({id:selectedArticle?.id ?? "create",autocompleteDetails:autoCompleteFields})
                    }
                    if(headers?.payload?.data?.restrictedWebsiteCheckbox && headers?.payload?.data?.restrictedWebsiteCheckbox && err?.response?.status === 400){
                        setErrorMessage({ key: 'referenceWebsite', errorTemplate: `Sorry I'm unable to find the response from the provided URL` })
                    }
                    //autoCompleteConfig.onSubmitAutoComplete(false)
                 }
            }
        )
        
    const executeAutoComplete = () => {
        resetError()
        setOnSubmitLoader(true)
        const isValid = validateFields(autoCompleteFields)
        if(isValid)autoCompleteDescriptions({data:autoCompleteFields})
    }
    const isValidURL = (url) => {
        const regex = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$|^[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i
        return regex.test(url)
    }
    const [currentMessageIndex, setCurrentMessageIndex] = useState(0)
    const messages = ["Analyzing Request","Gathering Context","Processing","Composing Response","Refining Output ","Finalizing"]
    useEffect(() => {
        if (onSubmitLoader && currentMessageIndex < messages.length - 1) {
        const timer = setTimeout(() => {
            setCurrentMessageIndex((prevIndex) => prevIndex + 1)
        }, 2000)
        return () => clearTimeout(timer)
        }
    }, [onSubmitLoader, currentMessageIndex])
    const strawmanExample = `1. Current Landscape\n- Overview\n- Key challenges\n\n2.  Analysis\n- Main factors driving change\n- Comparative analysis of approaches\n\n3. Solutions and Recommendations\n- Best practices\n- Implementation considerations\n\n4. Future Outlook\n- Potential challenges\n- Opportunities`
    return (
        <Drawer
            isOpen={autoCompleteConfig?.autoCompleteDrawerSettings}
            size="lg"
           // onClose={() => autoCompleteConfig.disableAutoComplete()}
        >
            <DrawerOverlay />
            <DrawerContent>
                <DrawerHeader p={"10px 24px"} {...(onSubmitLoader)?{position:'relative'}:{}}
                >
                     {onSubmitLoader &&  
                   <Flex  zIndex={2} position="absolute" top={0} left={0} right={0} bottom={0} justifyContent={'center'} alignItems={'center'}>
                    </Flex>}
                    <Box  display={"flex"} alignItems={"center"} gap={"8px"}  pt={"8px"} pb={'16px'} borderBottom={onSubmitLoader?'none' :"1px solid #d1d5db"}>
                        <Box {...(!onSubmitLoader ? { onClick: () => {resetAutoCompleteFields();autoCompleteConfig.disableAutoComplete()}} : {})}>
                            <FiArrowLeftCircle
                                height={"24px"}
                                width={"24px"}
                                color="#2563eb"
                                cursor={onSubmitLoader?'not-allowed':'pointer'}
                            />
                        </Box>
                        <Box
                            width={"100%"}
                            display={"flex"}
                            alignItems={"center"}
                            cursor={'default'}
                        >
                            <Text
                                fontSize={"16px"}
                                color={"#111827"}
                                fontWeight={"500"}
                                lineHeight={"normal"}
                            >
                                Auto Complete
                            </Text>
                        </Box>
                    </Box>
                </DrawerHeader>
                <DrawerBody {...(onSubmitLoader)?{position:'relative',overflowY:'hidden'}:{px:'24px', pt:'5px' ,pb:'10px' }}>
                {onSubmitLoader &&  
                   <Flex zIndex={2} position="absolute" top={0} left={0} right={0} bottom={0} justifyContent={'center'} alignItems={'center'}>
                        <Flex flexDirection={'column'} gap={1} alignItems={'center'} height={'205px'} width={'490px'} borderRadius={'10px'} bgColor={'rgba(0, 0, 0, 0.48)'} style={{boxShadow: '0px 0px 94px 14px rgba(0, 0, 0, 0.25)'}} pt={'5px'}>
                            <Box height={'139px'} width={'139px'}>
                                <Image src={RezoloLogoLoader}/>
                            </Box>
                            <Text size="14px" color={"#fff"} lineHeight={'normal'} fontSize={'16px'} fontWeight={600}>{messages[currentMessageIndex]}...</Text>
                        </Flex>
                    </Flex>}
                    <Box mb={"20px"}>
                        <Flex mb="8px">
                            <Text
                                fontFamily={"inter"}
                                fontSize={"14px"}
                                fontStyle={"normal"}
                                fontWeight={"500"}
                                lineHeight={"20px"}
                                cursor={'default'}
                            >
                                Title
                            </Text>
                        </Flex>
                        <Input
                            name="title"
                            _focus={{ ring: 0 }}
                            _placeholder={{ color: "#A9B1BC", fontWeight: "400" }}
                            placeholder="The title of the article"
                            sx={{
                                _disabled: {
                                  bg: '#E5E7EB4D',
                                  borderColor: '#E2E2E2',
                                  color: '#111827',
                                  fontWeight:500
                                },
                              }}
                            color={"#6b7280"}
                            fontWeight={"400"}
                            lineHeight={"20px"}
                            isDisabled={isTitlePresented?.trim() || onSubmitLoader}
                            borderRadius={"6px"}
                            border={"1px solid #D1D5DB"}
                            borderColor={"#D1D5DB"}
                            fontSize={"14px"}
                            onChange={(event) => onChangeTextFields(event)}
                            value={autoCompleteFields.title}
                        />
                        {errorMessage.key === 'title' && errorMessage?.errorTemplate && errorTemplate(errorMessage.errorTemplate)}
                    </Box>
                    <Box mb={"20px"}>
                        <Flex mb="8px" alignItems={"center"} gap={1}>
                            <Text
                                fontFamily={"inter"}
                                fontSize={"14px"}
                                fontStyle={"normal"}
                                fontWeight={"500"}
                                lineHeight={"20px"}
                                cursor={'default'}
                            >
                                Intent (Optional)
                            </Text>
                        </Flex>
                        <Textarea
                            _focus={{ ring: 0 }}
                            _placeholder={{ color: "#A9B1BC", fontWeight: "400" }}
                            placeholder="The intent of the article"
                            size="sm"
                            color={"#6b7280"}
                            fontWeight={"400"}
                            lineHeight={"20px"}
                            borderRadius={"6px"}
                            border={"1px solid #D1D5DB"}
                            borderColor={"#D1D5DB"}
                            fontSize={"14px"}
                            name="intent"
                            onChange={(event) => onChangeTextFields(event)}
                            value={autoCompleteFields.intent}
                            isDisabled={onSubmitLoader}
                        />
                        {errorMessage.key === 'intent' && errorMessage?.errorTemplate && errorTemplate(errorMessage.errorTemplate)}
                    </Box>
                    <Box mb={"20px"}>
                        <Flex mb="8px" alignItems={"center"} gap={1}>
                            <Text
                                fontFamily={"inter"}
                                fontSize={"14px"}
                                fontStyle={"normal"}
                                fontWeight={"500"}
                                lineHeight={"20px"}
                                cursor={'default'}
                            >
                                Brief Description
                            </Text>
                        </Flex>
                        <Textarea
                            _focus={{ ring: 0 }}
                            _placeholder={{ color: "#A9B1BC", fontWeight: "400" }}
                            placeholder="The brief description of the article"
                            size="sm"
                            color={"#6b7280"}
                            fontWeight={"400"}
                            lineHeight={"20px"}
                            borderRadius={"6px"}
                            border={"1px solid #D1D5DB"}
                            borderColor={"#D1D5DB"}
                            fontSize={"14px"}
                            name="briefDescription"
                            onChange={(event) => onChangeTextFields(event)}
                            value={autoCompleteFields.briefDescription}
                            isDisabled={onSubmitLoader}
                        />
                        {errorMessage.key === 'briefDescription' && errorMessage?.errorTemplate && errorTemplate(errorMessage.errorTemplate)}
                    </Box>
                    <Box mb={"20px"}>
                        <Flex mb="8px" alignItems={"center"} gap={1}>
                            <Text
                                fontFamily={"inter"}
                                fontSize={"14px"}
                                fontStyle={"normal"}
                                fontWeight={"500"}
                                lineHeight={"20px"}
                                cursor={'default'}
                            >
                                Strawman (Optional)
                            </Text>
                        </Flex>
                        <Textarea
                            _focus={{ ring: 0 }}
                            _placeholder={{ color: "#A9B1BC", fontWeight: "400",whiteSpace:'pre-wrap' }}
                            placeholder={strawmanExample}
                            size="sm"
                            color={"#6b7280"}
                            fontWeight={"400"}
                            lineHeight={"20px"}
                            borderRadius={"6px"}
                            border={"1px solid #D1D5DB"}
                            borderColor={"#D1D5DB"}
                            fontSize={"14px"}
                            name="strawman"
                            onChange={(event) => onChangeTextFields(event)}
                            value={autoCompleteFields.strawman}
                            isDisabled={onSubmitLoader}
                        />
                        {errorMessage.key === 'strawman' && errorMessage?.errorTemplate && errorTemplate(errorMessage.errorTemplate)}
                    </Box>
                    <Box mb={"20px"}>
                        <Flex mb="8px" alignItems={"center"} gap={5}>
                            <Text
                                fontFamily={"inter"}
                                fontSize={"14px"}
                                fontStyle={"normal"}
                                fontWeight={"500"}
                                lineHeight={"20px"}
                                cursor={'default'}
                            >
                            Citations
                            </Text>
                            <Switch size="md" sx={{'&[data-checked] .chakra-switch__track': {backgroundColor: '#2563EB'}}}  onChange={(event) => {resetError();setAutoCompleteFields({ ...autoCompleteFields, citation: event.target.checked })}} isChecked={autoCompleteFields.citation}/>
                        </Flex>
                    </Box>
                    <Flex
                        mb="8px"
                        alignItems={"center"}
                        gap={1}
                        justifyContent={"space-between"}
                    >
                        <Box>
                            <Text
                                fontFamily={"inter"}
                                fontSize={"14px"}
                                fontStyle={"normal"}
                                fontWeight={"500"}
                                lineHeight={"20px"}
                                cursor={'default'}
                            >
                                Reference websites
                            </Text>
                        </Box>
                       {autoCompleteFields?.referenceWebsite?.filter((val)=> val?.trim() !== '').length !== 0 && <Flex alignItems={'center'} position={'relative'} className="autocomplete">
                            <Flex className="parent">
                                <Box
                                    width={'24px'}
                                    display={"flex"}
                                    alignItems={"center"}
                                    cursor={"pointer"}
                                >
                                    <HiOutlineExclamationCircle size={"20px"} color="#94A3B8" />
                                </Box>
                                <Box
                                    p={"10px"}
                                    mt={"25px"}
                                    borderRadius={"6px"}
                                    boxShadow={"md"}
                                    position={"absolute"}
                                    background={'#fff'}
                                    width={"373px"}
                                    ml={'-120px'}
                                    style={{
                                        boxShadow:
                                            "0px 10px 15px -3px rgba(0, 0, 0, 0.10), 0px 4px 6px -2px rgba(0, 0, 0, 0.05), 0px 0px 0px 1px rgba(0, 0, 0, 0.05)",
                                    }}
                                    zIndex={1}
                                    className="child"
                                >
                                    <Flex gap={2}>
                                        <Box>
                                            <HiOutlineExclamationCircle
                                                size={"18px"}
                                                height={"24px"}
                                                width={"24px"}
                                                color="#ffa500"
                                            />
                                        </Box>
                                        <Text
                                            fontSize={"14px"}
                                            fontWeight={"400"}
                                            lineHeight={"20px"}
                                            color={"#111827"}
                                        >
                                            If restricted, only content from these websites will
                                            be used to compose the article.
                                        </Text>
                                    </Flex>
                                </Box>
                            </Flex>
                            <Flex alignItems={'center'} gap={3}>
                                <Text lineHeight={'16.94px'} color={'#6B7280'} fontWeight={'500'} fontSize={'14px'} pr={'6px'} cursor={'default'}>Restrict To Reference websites</Text>
                                <Switch size="md" sx={{'&[data-checked] .chakra-switch__track': {backgroundColor: '#2563EB'}}}    onChange={(event) => {resetError();setAutoCompleteFields({ ...autoCompleteFields, restrictedWebsiteCheckbox: event.target.checked })}} isChecked={autoCompleteFields.restrictedWebsiteCheckbox}/>
                            </Flex>
                        </Flex>}
                    </Flex>
                    {autoCompleteFields?.referenceWebsite?.map((varient, index) => (
                        <Flex direction='row' mb={"10px"} key={index} height={"40px"} width={'100%'} >
                            <Input style={{ flexGrow: 1 }} type="text" placeholder="Provide any URLs that may be relevent" borderColor='#E2E2E2' color='#6B7280' fontSize='14px' _placeholder={{ color: "#A9B1BC"}} value={varient} onChange={(e) => onChangeReferenceWebsite(index, e.target.value)} focusBorderColor="#2563EB" />
                            {autoCompleteFields?.referenceWebsite?.length > 1 && <Button ml={'4px'} {...(onSubmitLoader)? {}:{style:{cursor:'pointer'},onClick:()=> removeReferenceWebsite(index)}} backgroundColor={'transparent'} alignItems='center' p='3px' borderRadius='5px' justifyContent='center' as='span' border={`1.5px solid #CF3626}`}><DeleteIcon size='24px' color={"#CF3626"} ></DeleteIcon></Button>}
                            <Button ml={'4px'} {...(onSubmitLoader)? {}:{style:{cursor:'pointer'},onClick:()=>addReferenceWebsite()}} backgroundColor={'transparent'} display={index === autoCompleteFields?.referenceWebsite?.length - 1 ? 'flex' : 'none'} p='3px' alignItems='center' justifyContent='center' as='span' type='submit' borderRadius='5px' border={`1.5px solid #2563EB}`}><AiOutlinePlusCircle size='24px' color={'#2563EB'} /> </Button>
                        </Flex>)
                    )}
                    {errorMessage.key === 'referenceWebsite' && errorMessage?.errorTemplate && errorTemplate(errorMessage.errorTemplate)}
                </DrawerBody>
                <DrawerFooter {...(onSubmitLoader)?{position:'relative'}:{}}>
                {onSubmitLoader &&  
                   <Flex zIndex={2} position="absolute" top={0} left={0} right={0} bottom={0} justifyContent={'center'} alignItems={'center'}>
                    </Flex>}
                    <Box>
                        <Flex
                            pt={"30px"}
                            alignItems={"center"}
                            gap={"4"}
                            width={"100%"}
                            justifyContent={"flex-end"}
                        >
                            <Flex
                                {...(!onSubmitLoader ? { onClick: ()=>{resetAutoCompleteFields();autoCompleteConfig.disableAutoComplete()} } : {style:{backgroundColor:'#E5E7EB4D'}})} 
                                cursor={!onSubmitLoader?'pointer':'not-allowed'}
                                width={"102px"}
                                height={"36px"}
                                p={"0px 28px"}
                                borderRadius={"5px"}
                                border={"1px solid #D1D5DB"}
                                alignItems={"center"}
                                justifyContent={"center"}
                            >
                                <Text
                                    style={{
                                        fontFamily: "Inter",
                                        fontSize: "14px",
                                        fontWeight: 400,
                                        lineHeight: "16.94px",
                                        color: "#6B7280"
                                    }}
                                >
                                    Cancel
                                </Text>
                            </Flex>
                            <Flex
                                {...(!onSubmitLoader ? { onClick: executeAutoComplete ,style:{backgroundColor:'#E79B04'} } : {style:{backgroundColor:'#E79B044D'}})} 
                                cursor={!onSubmitLoader?'pointer':'not-allowed'}
                                width={"150px"}
                                height={"36px"}
                                p={"0px 12px 0px 12px"}
                                borderRadius={"5px"}
                                alignItems={"center"}
                                justifyContent={"center"}
                                color={"#fff"}
                            >
                                <FaMagic size={"14.88px"} color="#fff" />
                                <Text
                                    style={{
                                        fontFamily: "Inter",
                                        fontSize: "14px",
                                        fontWeight: 400,
                                        lineHeight: "16.94px",
                                        color: "#fff",
                                        marginLeft: "10px",
                                    }}                                    
                                >
                                    Auto Complete
                                </Text>
                            </Flex>
                        </Flex>
                    </Box>
                </DrawerFooter>
            </DrawerContent>
        </Drawer>
    )
}
export default AutoComplete